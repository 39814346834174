<template lang="pug">
TheModal(v-model:show="show" @update:show="$emit('update:show', $event)")
	BaseInput(@update:modelValue="onSearchRegion" placeholder='Поиск участка' v-model="search" icon="search").mb-4
	TheList(v-if="Object.keys(regions).length > 0" :map="regions" @onClick="addRegion")
	.flex.flex--jcc(v-else)
		span() Участки не найдены
</template>

<script>
import TheModal from '../TheModal'
import TheList from '../TheList'
import BaseInput from '@/components/ui/BaseInput.vue'

import { onMounted, reactive, ref, watch } from 'vue'
import { useApi } from '@/modules/api'

export default {
	name: 'ModalRegions',
	components: { TheList, TheModal, BaseInput },
	props: {
		show: {
			type: Boolean,
			required: true
		}
	},
	emits: ['update:show', 'add'],
	setup (props, { emit }) {
		const regions = reactive({})
		const search = ref('')
		watch(
			() => props.show,
			async () => {
				if (!props.show) {
					search.value = ''
					await getAllRegions()
				}
			}
		)

		const onSearchRegion = async (value) => {
			const { get, data } = useApi(`dictionary/fisherRegion${value ? `/search?&search=${value}` : ''}`)
			await get()
			Object.keys(regions).forEach(key => delete regions[key])
			data.value.forEach(({ id, name }) => {
				regions[id] = { left: name }
			})
		}
		const getAllRegions = async () => {
			const { get, data } = useApi('/dictionary/fisherRegion')

			await get()

			data.value.forEach(({ id, name }) => {
				regions[id] = { left: name }
			})
		}
		onMounted(async () => {
			await getAllRegions()
		})

		const addRegion = (id) => {
			const { left: title } = regions[id]
			emit('add', { id, title })
			emit('update:show', false)
		}

		return {
			regions,
			search,
			onSearchRegion,
			addRegion
		}
	}
}
</script>
