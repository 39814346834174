<template lang="pug">
TheDetail(
	:groups="groups"
	title="Добытчик"
	title-new="Создание добытчика"
	@showBoat="showBoat"
	@deleteBoat="deleteBoat"
	@remove="removeEarner"
	@save="saveEarner"
	ref="detail"
)
ModalBoat(v-model:show="modals.boat" @create="createBoat" @edit="editBoat")
ModalWorkers(v-model:show="modals.workers" endpoint="boardCompany" @create="createWorker")
ModalRegions(v-model:show="modals.regions" @add="addRegion")
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'

import { useApi } from '../modules/api'
import { useBasic } from '../modules/composition/inputs/useBasic'
import { useDetail } from '../modules/composition/useDetail'
import { useShips } from '../modules/composition/useShips'
import { useWorkers } from '../modules/composition/useWorkers'
import { useRegions } from '../modules/composition/useRegions'

import TheDetail from '../components/TheDetail'
import ModalBoat from '../components/modals/ModalBoat'
import ModalWorkers from '../components/modals/ModalWorkers'
import ModalRegions from '../components/modals/ModalRegions'

export default {
	name: 'EarnerDetail',
	components: { TheDetail, ModalBoat, ModalWorkers, ModalRegions },
	setup () {
		const { idDetail, isNew, notFoundDetail, saveDetail, removeDetail } = useDetail()

		const detail = ref(null)

		const saveEarner = async () => {
			await saveDetail({
				endpoint: '/boardCompany/create',
				body: { ...bodyBasic(), fisherRegions: getRegionsIds() },
				workers,
				createWorkerApi
			})
		}

		const removeEarner = async () => {
			await removeDetail(`/boardCompany/${idDetail}`)
		}

		const createWorkerApi = async ({ id, workerId, postId }) => {
			const { post, data } = useApi('/boardCompany/createBoardCompany2worker')

			await post({
				boardCompany: { id: id || idDetail },
				worker: { id: workerId },
				workerPost: { id: postId }
			})

			return data.value?.id
		}

		const updateWorkerApi = () => {
			return new Promise((resolve, reject) => {
				(async () => {
					try {
						const { get, data, error } = useApi(`/boardCompany/${idDetail}`)

						await get()

						if (error.value) {
							throw new Error('Не удалось обновить список работников')
						}

						const workerList = data.value.listOfWorkers.reduce((acc, worker) => {
							const { id, workerFIO: title, postName: status } = worker
							acc[id] = { id, title, status }
							return acc
						}, {})
						resolve(workerList)
					} catch (error) {
						reject(error)
					}
				})()
			})
		}

		const { workers, setWorker, createWorker, deleteWorker } = useWorkers({
			updateWorkerApi,
			createWorkerApi,
			deleteEndpoint: '/boardCompany/deleteBoardCompany2worker/',
			idDetail,
			isNew
		})

		const { ships, getShipId, setShips, createBoat, editBoat, deleteBoat } = useShips({
			idDetail,
			field: 'boardCompany',
			createEndpoint: '/boardCompany/createBoardCompany2ship',
			deleteEndpoint: '/boardCompany/deleteBoardCompany2ship'
		})

		const { regions, deleteRegion, addRegion, getRegionsIds } = useRegions({
			detail
		})

		const { basic, setBasic, bodyBasic } = useBasic()

		const modals = reactive({
			workers: false,
			boat: false,
			regions: false
		})

		const showBoat = id => {
			modals.boat = getShipId(id)
		}

		const groups = reactive({
			main: {
				title: 'Основные данные',
				grid: 3,
				inputs: basic
			},
			listOfShips: {
				title: 'Данные о судах',
				hideNew: true,
				grid: 1,
				count: computed(() => Object.keys(groups.listOfShips.ships).length),
				icon: 'plus',
				onClick: () => {
					modals.boat = true
				},
				disabled: computed(() => Object.keys(groups.listOfShips.ships).length > 0),
				ships: ships
			},
			listOfWorkers: {
				title: 'Работники',
				count: computed(() => Object.keys(groups.listOfWorkers.cards).length),
				icon: 'plus',
				onClick: () => {
					modals.workers = true
				},
				grid: 2,
				iconCard: 'user',
				button: 'Удалить',
				buttonClick: deleteWorker,
				cards: workers
			},
			fisherRegions: {
				title: 'Рыболовные участки',
				count: computed(() => Object.keys(groups.fisherRegions.cards).length),
				icon: 'plus',
				onClick: () => {
					modals.regions = true
				},
				grid: 2,
				iconCard: 'region',
				button: 'Удалить',
				buttonClick: deleteRegion,
				cards: regions
			}
		})

		onMounted(async () => {
			if (!isNew) {
				const { get: getMain, data: dataMain, error } = useApi(`/boardCompany/${idDetail}`)

				await getMain()

				if (error.value) return notFoundDetail()

				const {
					name, phone,
					listOfShips,
					listOfWorkers,
					fisherRegions
				} = dataMain.value

				setBasic({ name, phone })
				setShips(listOfShips)

				listOfWorkers.forEach(worker => {
					const { id, workerFIO: title, postName: status } = worker
					setWorker({ id, title, status })
				})

				fisherRegions.forEach(region => {
					const { name, id } = region
					regions[id] = { title: name }
				})
			} else {
				setBasic({ name: '', phone: '' })
			}
		})

		return {
			groups,
			detail,
			modals,

			createWorker,

			addRegion,

			getShipId,
			createBoat,
			editBoat,
			showBoat,
			deleteBoat,

			saveEarner,
			removeEarner
		}
	}
}
</script>
